import { createDatasource } from "@/api"
import type { Datasource } from "@/model"
import * as Label from "@radix-ui/react-label"
import { useRef, useState, useEffect, useCallback } from "react"

interface FileUploadProps {
  onFilesUploaded: (datasource: Datasource) => void
  onIsUploading?: () => void
  onFilesUploadedError: (err: any) => void
  isPendingDatasource?: boolean
  accept?: string
  multiple?: boolean
}

// Base rate: 100MB per 150 seconds
const UPLOAD_RATE_MB_PER_SECOND = 0.667;

const loadingMessages = [
  { timeThreshold: 2, message: "Starting upload..." },
  { timeThreshold: 15, message: "Still processing... This might take a few moments" },
  { timeThreshold: 30, message: "Processing larger files can take a bit longer" },
  { timeThreshold: 60, message: "Handling complex data... Thank you for your patience" },
  { timeThreshold: 90, message: "Almost there! Final processing steps..." },
  { timeThreshold: 120, message: "This is taking longer than usual. Please hang tight..." }
]

// Easing function to make progress non-linear
const easeProgress = (progress: number): number => {
  // Split the upload into phases
  if (progress < 0.2) {
    // Initial phase: Quick start (quadratic ease-out)
    return (progress / 0.2) * (progress / 0.2) * 25;
  } else if (progress < 0.8) {
    // Middle phase: Slower, more steady progress (linear with slight curve)
    const normalized = (progress - 0.2) / 0.6;
    return 25 + normalized * 50;
  } else {
    // Final phase: Gradually slow down (cubic ease-in)
    const normalized = (progress - 0.8) / 0.2;
    const eased = 1 - Math.pow(1 - normalized, 3);
    return 75 + eased * 15; // Max out at 90%
  }
};

export const FileUpload: React.FC<FileUploadProps> = ({
  onFilesUploaded,
  onIsUploading,
  onFilesUploadedError,
  isPendingDatasource,
  accept = "csv,excel,pscad",
  multiple = false,
}) => {
  const [dragActive, setDragActive] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [uploadStartTime, setUploadStartTime] = useState<number | null>(null)
  const [currentMessage, setCurrentMessage] = useState("")
  const [progress, setProgress] = useState(0)
  const [totalSize, setTotalSize] = useState(0)
  const [estimatedTotalSeconds, setEstimatedTotalSeconds] = useState(0)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const uploadCompleteRef = useRef(false)

  const calculateProgress = useCallback((elapsedSeconds: number, totalSeconds: number) => {
    const rawProgress = Math.min(elapsedSeconds / totalSeconds, 1);
    return easeProgress(rawProgress);
  }, []);

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;

    if (isUploading && uploadStartTime && totalSize > 0) {
      uploadCompleteRef.current = false;
      intervalId = setInterval(() => {
        const elapsedSeconds = (Date.now() - uploadStartTime) / 1000;

        if (!uploadCompleteRef.current) {
          const currentProgress = calculateProgress(elapsedSeconds, estimatedTotalSeconds);
          setProgress(currentProgress);
        }

        // Update loading message based on elapsed time
        const appropriateMessage = [...loadingMessages]
          .reverse()
          .find(({ timeThreshold }) => elapsedSeconds >= timeThreshold);

        if (appropriateMessage) {
          setCurrentMessage(appropriateMessage.message);
        }
      }, 50); // More frequent updates for smoother animation
    } else {
      setCurrentMessage("");
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isUploading, uploadStartTime, totalSize, estimatedTotalSeconds, calculateProgress]);

  const calculateEstimatedTime = (totalBytes: number) => {
    const totalMB = totalBytes / (1024 * 1024);
    return totalMB / UPLOAD_RATE_MB_PER_SECOND;
  };

  const handleDrag = (e: React.DragEvent<HTMLDivElement | HTMLFormElement>) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true)
    } else if (e.type === "dragleave") {
      setDragActive(false)
    }
  }

  const uploadFiles = async (fileList: FileList) => {
    if (fileList && fileList.length > 0) {
      const files = Array.from(fileList)
      const formData = new FormData()
      const totalBytes = files.reduce((acc, file) => acc + file.size, 0)

      files.forEach(file => formData.append("files", file))

      try {
        setIsUploading(true)
        setUploadStartTime(Date.now())
        setTotalSize(totalBytes)
        setEstimatedTotalSeconds(calculateEstimatedTime(totalBytes))
        setProgress(0)
        onIsUploading?.()

        const response = await createDatasource(formData, isPendingDatasource)
        uploadCompleteRef.current = true;
        setProgress(100)
        onFilesUploaded(response)
      } catch (err) {
        onFilesUploadedError(err)
      } finally {
        setTimeout(() => {
          setIsUploading(false)
          setUploadStartTime(null)
          setTotalSize(0)
          setProgress(0)
          uploadCompleteRef.current = false;
        }, 500)
      }
    }
  }

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    uploadFiles(e.dataTransfer.files)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      uploadFiles(e.target.files)
    }
  }

  const formatFileSize = (bytes: number) => {
    if (bytes === 0) return '0 Bytes'
    const k = 1024
    const sizes = ['Bytes', 'KB', 'MB', 'GB']
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i]
  }

  return (
    <form className="space-y-4" onDragEnter={handleDrag}>
      <div
        className={`flex items-center justify-center w-full ${dragActive ? "border-blue-500" : "border-gray-300"
          } relative`}
      >
        <Label.Root
          htmlFor="file-upload"
          className={`flex flex-col items-center justify-center w-full h-32 border-2 border-dashed rounded-lg cursor-pointer ${dragActive ? "bg-blue-50" : "bg-gray-50 hover:bg-gray-100"
            }`}
        >
          <div className="flex flex-col items-center justify-center pt-5 pb-6">
            {isUploading ? (
              <>
                <div className="w-64 space-y-2">
                  <div className="flex justify-center text-sm text-gray-500">
                    <span>{formatFileSize(totalSize)}</span>
                  </div>
                  <div className="h-2 w-full bg-gray-200 rounded-full overflow-hidden">
                    <div
                      className="h-full bg-blue-500 transition-all duration-200 ease-out"
                      style={{ width: `${progress}%` }}
                    />
                  </div>
                  <p className="text-sm text-gray-500 text-center animate-pulse">
                    {currentMessage || "Uploading..."}
                  </p>
                </div>
              </>
            ) : (
              <>
                <svg
                  className="w-8 h-8 mb-4 text-gray-500"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 16"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                  />
                </svg>
                <p className="mb-2 text-sm text-gray-500">
                  <span className="font-semibold">Click to upload</span> or drag and
                  drop
                </p>
                {accept && (
                  <p className="text-xs text-gray-500">
                    {accept.split(",").join(" or ")} files are allowed
                  </p>
                )}
              </>
            )}
          </div>
          <input
            id="file-upload"
            type="file"
            name="file"
            className="hidden"
            ref={fileInputRef}
            onChange={handleChange}
            disabled={isUploading}
            accept={accept}
            multiple={multiple}
          />
        </Label.Root>
        {dragActive && (
          <div
            className="absolute inset-0"
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          ></div>
        )}
      </div>
    </form>
  )
}